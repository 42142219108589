// npm安装：npm install qiniu-js
import * as qiniu from 'qiniu-js'
import { stringify } from 'qs';

function createHash (hashLength) {
  if (!hashLength || typeof(Number(hashLength)) != 'number') {return;}
  var ar = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  var hs = [];
  var hl = Number(hashLength);
  var al = ar.length;
  for (var i = 0; i < hl; i ++) {
      hs.push(ar[Math.floor(Math.random() * al)]);
  }
   
  return hs.join('');
}

// 七牛云上传
export function qiniudnUploader (token, file, key, obj) {
  
  var keyall = key + (new Date()).getTime() + '1' + Math.floor(Math.random() * 10000) + createHash(16) // 当前时间戳+随机数

  var putExtra = {
    fname: '',
    params: {},
    mimeType: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'] // 不限制 null
  }

  var config = {
    useCdnDomain: true,
    region: qiniu.region.z0
  }

  // 上传回调
  var observer = {
    next (res) {
      // ...
      console.log('next：' + JSON.stringify(res))
    },
    error (err) {
      // ...
      console.log('error：' + err)
    },
    complete (res) {
      // ...
      obj(res)
      console.log('complete：' + JSON.stringify(res))
    }
  }

  var observable = qiniu.upload(file, keyall, token, putExtra, config)
  observable.subscribe(observer) // 上传开始
};


// export { qiniudnUploader ,delete}

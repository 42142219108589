var uploads = (e, upload) => {
    // 图片选择
    var file = e
    // console.log(file)
    if (file === undefined) { return }
    if (file.size / 1024 > 1025) { // 文件大于1M（根据需求更改），进行压缩上传
      photoCompress(file, { // 调用压缩图片方法
        quality: 0.2
      }, function (base64Codes) {
        // console.log("压缩后：" + base.length / 1024 + " " + base);
        var bl = baseUrlToBlob(base64Codes)
        // console.log(bl)
        // file.append('file', bl, 'file_' + Date.parse(new Date()) + '.jpg') // 文件对象
        upload(bl) // 请求图片上传接口
      })
    } else { // 小于等于1M 原图上传
      upload(file)
    }
  }
  
  // base64 转 Blob 格式 和file格式
  var baseUrlToBlob = (urlData) => {
    var arr = urlData.split(',')
    var mime = arr[0].match(/:(.*?);/)[1] // 去掉url的头，并转化为byte
    var bstr = atob(arr[1]) // 处理异常,将ascii码小于0的转换为大于0
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    // 转blob
    // return new Blob([u8arr], {type: mime})
    var filename = Date.parse(new Date()) + '.jpg'
    // 转file
    return new File([u8arr], filename, { type: mime })
  }
  
  // 压缩图片
  // file：文件(类型是图片格式)，
  // obj：文件压缩后对象width， height， quality(0-1)
  // callback：容器或者回调函数
  var photoCompress = (file, obj, callback) => {
    var ready = new FileReader()
    /* 开始读取指定File对象中的内容. 读取操作完成时,返回一个URL格式的字符串. */
    ready.readAsDataURL(file)
    ready.onload = function () {
      var re = this.result
      canvasDataURL(re, obj, callback) // 开始压缩
    }
  }
  
  /* 利用canvas数据化图片进行压缩 */
  /* 图片转base64 */
  var canvasDataURL = (path, obj, callback) => {
    var img = new Image()
    img.src = path
    img.onload = function () {
      var that = this // 指到img
      // 默认按比例压缩
      var w = that.width
      var h = that.height
      var scale = w / h
      w = obj.width || w
      h = obj.height || (w / scale)
      var quality = 0.2 // 默认图片质量为0.7
      // 生成canvas
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      // 创建属性节点
      var anw = document.createAttribute('width')
      anw.nodeValue = w
      var anh = document.createAttribute('height')
      anh.nodeValue = h
      canvas.setAttributeNode(anw)
      canvas.setAttributeNode(anh)
      ctx.drawImage(that, 0, 0, w, h)
      // 图像质量
      if (obj.quality && obj.quality >= 1 && obj.quality < 0) {
        quality = obj.quality
      }
      // quality值越小，所绘制出的图像越模糊
      var base64 = canvas.toDataURL('image/jpeg', quality)
      // 回调函数返回base64的值
      callback(base64)
    }
  }
  
  export { uploads }
  